import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Accordion.scss';

class Accordion extends Component {
  state = {
    open: false,
  };

  render() {
    const { open } = this.state;
    const { item } = this.props;

    return (
      <li
        className={classNames('Accordion__item', open && 'open')}
        itemScope
        itemProp="mainEntity"
        itemttype="https://schema.org/Question"
      >
        <h3
          className="Accordion__item__heading h3 toggle-link"
          itemProp="name"
          onClick={() => this.setState({ open: !open })}
          onKeyDown={() => this.setState({ open: !open })}
          role="presentation"
        >
          { item.title }
        </h3>
        <div
          className="Accordion__item__body"
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          style={{ height: open ? this.divElement.offsetHeight : 0 }}
        >
          <div
            className="Accordion__item__content"
            itemProp="text"
            ref={(divElement) => { this.divElement = divElement; }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      </li>
    );
  }
}

Accordion.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Accordion;
