import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const HowItWorks = ({
  customClass,
  title,
  subtitle,
  steps,
  buttonUrl,
  buttonTitle,
}) => (
  <Fragment>
    <section className={`section section-howitworks ${customClass}`}>
      <div className="container">
        <div>
          <h2 className="section__title">
            { title }
          </h2>
          {subtitle !== '' ? (<p className="section__subtitle">{ subtitle }</p>) : ''}
          <ol className="section-howitworks__list list--clean">
            {[].slice.call(steps).map(step => (
              <li key={step.title}>
                <div className="section-howitworks__item-title">{ step.title }</div>
                <div className="section-howitworks__item-text">{ step.text }</div>
              </li>
            ))}
          </ol>
        </div>
        <div className="section-howitworks__btn">
          <a href={buttonUrl} rel="nofollow" className="btn btn--green-fill btn--with-arrow">{ buttonTitle }</a>
        </div>
      </div>
    </section>

  </Fragment>
);

HowItWorks.propTypes = {
  customClass: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  steps: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  buttonUrl: PropTypes.string,
  buttonTitle: PropTypes.string,
};

HowItWorks.defaultProps = {
  customClass: '',
  title: 'How our service works',
  subtitle: 'It takes two to tango and four to order our writing assistance! Follow these simple instructions to deal with your writing disasters:',
  buttonUrl: 'https://advancedwriters.com/dashboard/orders/create/',
  buttonTitle: 'To the order form',
};

export default HowItWorks;
