import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/splide/dist/css/splide.min.css';
import './index.scss';

const Writers = ({ title, subtitle, writers }) => (
  <Fragment>
    <section className="section section-writers">
      <Splide
        className="writers__slider"
        options={{
          perPage: 3,
          perMove: 1,
          gap: '24px',
          breakpoints: {
            1200: {
              perPage: 2,
              pagination: false,
            },
            767: {
              perPage: 2,
              pagination: true,
            },
            600: {
              perPage: 1,
            },
          },
        }}
        renderControls={() => (
          <div className="section-writers__header splide__arrows">
            <div className="container">
              <div className="section-writers__header__content">
                <h2 className="section__title">{ title }</h2>
                <div className="section__subtitle">{ subtitle }</div>
              </div>
              <div className="section-writers__header__buttons">
                <button type="button" className="splide__arrow splide__arrow--prev" />
                <button type="button" className="splide__arrow splide__arrow--next" />
              </div>
            </div>
          </div>
        )}
      >
        {
          [].slice.call(writers).map(writer => (
            <SplideSlide key={writer.name} className="writer">
              <div className="writer__header">
                <div className="writer__header--img">
                  <LazyLoadComponent>
                    <picture>
                      <source
                        srcSet={`/pictures/writers/${writer.name.replace(' ', '_').toLowerCase()}.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`/pictures/writers/${writer.name.replace(' ', '_').toLowerCase()}.jpg`}
                        width="100"
                        height="100"
                        alt=""
                      />
                    </picture>
                  </LazyLoadComponent>
                </div>
                <div className="writer__header--info">
                  <div className="h4 writer__header--name">{ writer.name }</div>
                  <div className="writer__header--rating">Rating:
                    <span className="writer__header--rating-stars">
                      {[1, 2, 3, 4, 5].map(i => (
                        <i
                          key={i}
                          className={`writer__header--rating-star-${(+i <= writer.rating) ? 'fill' : 'unfill'}`}
                        />
                      ))}
                    </span>
                  </div>
                  <div className="writer__header--cols">
                    <div className="writer__header--experience">
                      <strong>{ writer.experience } years</strong>
                      of experience
                    </div>
                    <div className="writer__header--papers">
                      <strong>{ writer.papers }</strong>
                      papers written
                    </div>
                  </div>
                </div>
              </div>
              <div className="writer__skills">
                <div className="writer__skills--degree">
                  <div className="writer__skills--title">Degree:</div>
                  <div className="writer__skills--data">{ writer.degree }</div>
                </div>
                <div className="writer__skills--separetor" />
                <div className="writer__skills--competences">
                  <div className="writer__skills--title">Competences:</div>
                  <div className="writer__skills--data">{ writer.specializes }</div>
                </div>
              </div>
              <div className="writer__feedback">
                <div className="writer__feedback--body">{ writer.client_comment }</div>
                <div className="writer__feedback--user">
                  <div className="writer__feedback--user-id">User ID: #{ writer.client_id }</div>
                  <div className="writer__feedback--user-discipline">{ writer.client_discipline }</div>
                </div>
              </div>
            </SplideSlide>
          ))
        }
      </Splide>
    </section>
  </Fragment>
);

Writers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  writers: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Writers.defaultProps = {
  title: 'Writers',
  subtitle: '',
};


export default Writers;
