/* globals Calc */
// @todo enable next eslint rules
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class Calculator extends Component {
  componentDidMount() {
    function getScript(url, callback) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.querySelector('[data-calc-mini]').appendChild(script);
    }

    if (!document.querySelector('script[src="/assets/js/calc.js"]')) {
      getScript('/assets/js/calc.js', () => {
        this.getCalc();
      });
    }
  }

  getCalc = () => {
    const {
      academicLevel,
      paperType,
      pages,
    } = this.props;

    new Calc({
      ID: '3',
      calculatorType: 'DefaultCalculator',
      format: 'json',
      options: {
        deafault: {
          deadlinesDefault: [105301, 105349, 105399, 105439, 105487],
          academicLevelDefault: academicLevel,
          paperTypesDefault: paperType,
          pagesDefault: pages,
        },
      },
    });
  };

  render() {
    return (
      <div className="Calculator" data-calc-mini data-calculator-default>
        <form name="uvocalculator" className="Calc" method="get" action="/" data-calculator-form>
          <select name="topcatId" style={{ display: 'none' }} data-discipline-select />
          <div className="Calc__wrapper">
            <div className="Calc__cell Calc__cell--type-of-paper">
              <div className="Calc__select select">
                <select id="calc-type-of-paper" name="paperTypeId" data-academic-level-select>
                  <option value="2">Essay (any type)</option>
                </select>
                <label htmlFor="calc-type-of-paper" className="Calc__label">Type of paper</label>
              </div>
            </div>

            <div className="Calc__cell Calc__cell--academic-level">
              <div className="visible-in-mobile">
                <div className="Calc__select select">
                  <select id="calc-academic-level" data-academic-level-select-mobile>
                    <option value="">Undergrad. (yrs 1-2)</option>
                  </select>
                  <label htmlFor="calc-academic-level" className="Calc__label">Academic level</label>
                </div>
              </div>
              <div className="acdemic-levels ui-buttonset visible-in-desktop Calc--hidden" data-academic-level-control-upgrade>
                <label>High School</label>
                <label className="current">Undergrad. (yrs 1-2)</label>
                <label>Undergrad. (yrs 3-4)</label>
                <label>Master's</label>
                <label>Doctoral</label>
              </div>
            </div>

            <div className="Calc__cell--academic-level-mobile">
              <div className="Calc--hidden Calc__academic-level--active-deadline" data-tariff-control-upgrade />
              <div className="Calc__academic-level--mobile" data-tariff-control-mobile>
                <div className="Loader Loader--center-mode">
                  <div className="Loader__inner" />
                </div>
              </div>
            </div>

            <div className="Calc__cell Calc__cell--pages">
              <label className="Calc__label">Pages <span className="CalcFull__label-item-words" data-words-qty>550 words</span></label>
              <span className="Calc__pages">
                <label className="Calc__pages-btn CalcFull__pages-left" htmlFor="pageDecrease">
                  <button id="pageDecrease" type="button" data-minus-button aria-label="decrease" />
                </label>
                <label className="Calc__pages-input" htmlFor="pages">
                  <input className="input" type="number" inputMode="numeric" id="pages" name="pages" data-pages-input defaultValue="1" />
                </label>
                <label className="Calc__pages-btn CalcFull__pages-right" htmlFor="pageIncrease">
                  <button id="pageIncrease" type="button" data-plus-button aria-label="increase" />
                </label>
              </span>
            </div>

            <div className="Calc__tip Calc__tip--deadline">
              <div className="Calc__tip__deadline">
                We'll send you the first draft for approval by <span data-ext-time />
              </div>
            </div>

            <div className="Calc__tip" style={{ display: 'none' }} data-tip-discount>You will get a personal manager and a discount.
            </div>
            <div className="Calc__tip" data-notice />
            <div className="Calc__total">
              <div className="Calc__total-value">Price: <span data-total-price><span>$</span>0</span></div>
            </div>
            <div className="Calc__submit">
              <button className="btn btn--green-fill" type="submit" data-submit-button>Continue to order
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Calculator.propTypes = {
  academicLevel: PropTypes.number,
  paperType: PropTypes.number,
  pages: PropTypes.number,
};

Calculator.defaultProps = {
  academicLevel: 2,
  paperType: 2,
  pages: 2,
};

export default Calculator;
