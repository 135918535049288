import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import './index.scss';

const Banner = ({
  className,
  title,
  subtitle,
  buttonUrl,
  buttonTitle,
}) => (
  <Fragment>
    <section className={`section section-banner ${className}`}>
      <div className="container">
        <div>
          <LazyLoadComponent>
            <h2 className="section__title section-banner__title">
              { title }
            </h2>
          </LazyLoadComponent>
          {subtitle !== '' ? (<p className="section__subtitle section-banner__subtitle">{ subtitle }</p>) : ''}
        </div>
        <div className="section-banner__button">
          <a href={buttonUrl} rel="nofollow" className="btn btn--white-fill btn--with-arrow">{ buttonTitle }</a>
        </div>
      </div>
    </section>

  </Fragment>
);

Banner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTitle: PropTypes.string,
};

Banner.defaultProps = {
  className: '',
  title: 'Banner',
  subtitle: '',
  buttonUrl: 'https://advancedwriters.com/dashboard/orders/create/',
  buttonTitle: 'Order now',
};

export default Banner;
