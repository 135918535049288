import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Accordion from './Accordion';

const Faq = ({ className, title, faq }) => (
  <section
    className={classNames(
      'section',
      className,
    )}
  >
    <div className="container">
      <h2 className="section__title">{ title }</h2>
      <ul
        className="Accordion list--clean"
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        {[].slice.call(faq).map(faqItem => <Accordion key={faqItem.title} item={faqItem} />)}
      </ul>
    </div>
  </section>
);

Faq.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  faq: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Faq.defaultProps = {
  title: 'FAQ',
  className: undefined,
};

export default Faq;
