/* eslint-disable no-undef */
/* eslint-disable no-new */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/splide/dist/css/splide.min.css';

import logoSitejabber from '../../img/logo_sitejabber.svg';

import './index.scss';

class Testimonials extends Component {
  state = {
    activeTab: 1,
  };

  componentDidMount() {
    function getScript(url, callback) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.querySelector('body').appendChild(script);
    }

    if (!document.querySelector('script[src="//static.sitejabber.com/js/widget.min.1610648276.js"]')) {
      getScript('//static.sitejabber.com/js/widget.min.1610648276.js', () => {
        new STJR.ReviewCarouselWidget({
          id: 'reviewCarouselWidget',
          url: 'advancedwriters.com',
        }).render();
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { title, subtitle, testimonials } = this.props;

    return (
      <Fragment>
        <section className="section section-testimonials">
          <div className="container">
            <h2 className="section__title">
              { title }
            </h2>
            {subtitle !== '' ? (<p className="section__subtitle">{ subtitle }</p>) : ''}
          </div>
          <div className="testimonials">
            <div className="container">
              <ul className="testimonials__nav Tabs__nav list--clean" role="tablist">
                <li
                  className={classNames('testimonials__nav__item Tabs__nav__item', activeTab === 1 && 'is-active')}
                  onClick={() => this.setState({ activeTab: 1 })}
                  onKeyDown={() => this.setState({ activeTab: 1 })}
                  role="tab"
                >
                  <LazyLoadComponent>
                    <img src={logoSitejabber} width={104} height={20} title="Sitejabber" alt="Sitejabber" />
                  </LazyLoadComponent>
                </li>
                <li
                  className={classNames('testimonials__nav__item Tabs__nav__item', activeTab === 2 && 'is-active')}
                  onClick={() => this.setState({ activeTab: 2 })}
                  onKeyDown={() => this.setState({ activeTab: 2 })}
                  role="tab"
                >
                  Site users
                </li>
              </ul>
            </div>
            <div className="testimonials__content Tabs__content">
              <div
                className={classNames('testimonials__content__item Tabs__content__item', activeTab === 1 && 'is-active')}
                role="tabpanel"
                tabIndex={0}
              >
                <div id="reviewCarouselWidget">
                  <div id="reviewCarouselWidgetInner" className="stjr-widget" />
                </div>
              </div>
              <div
                className={classNames('testimonials__content__item Tabs__content__item', activeTab === 2 && 'is-active')}
                role="tabpanel"
                tabIndex={0}
              >
                <Splide
                  className="reviews__slider"
                  options={{
                    perPage: 3,
                    focus: 0,
                    gap: '24px',
                    arrows: false,
                    pagination: true,
                    breakpoints: {
                      1200: {
                        perPage: 2,
                        pagination: true,
                      },
                      767: {
                        perPage: 2,
                        pagination: true,
                      },
                      600: {
                        perPage: 1,
                        pagination: true,
                      },
                    },
                  }}
                >
                  {
                    [].slice.call(testimonials).map(testimonial => (
                      <SplideSlide key={testimonial.toptitle} className="review">
                        <div className="review__header">
                          <div className="review__header-left">
                            <div className="review__header--title">{ testimonial.toptitle }</div>
                            <div className="review__header--category">{ testimonial.topcat }</div>
                          </div>
                          <div className="review__header-right">
                            <div className="review__header--id">ID { testimonial.customer_id }</div>
                          </div>
                        </div>
                        <div className="review__body">{ testimonial.body }</div>
                      </SplideSlide>
                    ))
                  }
                </Splide>

              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

Testimonials.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  testimonials: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Testimonials.defaultProps = {
  title: 'Testimonials',
  subtitle: '',
};

export default Testimonials;
